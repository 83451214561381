/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      counter
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      counter
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      counter
      createdAt
      updatedAt
    }
  }
`;
export const createManiData = /* GraphQL */ `
  mutation CreateManiData(
    $input: CreateManiDataInput!
    $condition: ModelManiDataConditionInput
  ) {
    createManiData(input: $input, condition: $condition) {
      id
      email
      name
      period
      counter
      notes
      createdAt
      updatedAt
    }
  }
`;
export const updateManiData = /* GraphQL */ `
  mutation UpdateManiData(
    $input: UpdateManiDataInput!
    $condition: ModelManiDataConditionInput
  ) {
    updateManiData(input: $input, condition: $condition) {
      id
      email
      name
      period
      counter
      notes
      createdAt
      updatedAt
    }
  }
`;
export const deleteManiData = /* GraphQL */ `
  mutation DeleteManiData(
    $input: DeleteManiDataInput!
    $condition: ModelManiDataConditionInput
  ) {
    deleteManiData(input: $input, condition: $condition) {
      id
      email
      name
      period
      counter
      notes
      createdAt
      updatedAt
    }
  }
`;
