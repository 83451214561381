/* src/App.js */
import React, { useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { deleteTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'
import * as mutations from './graphql/mutations';

import background from "./mantrawheel.jpeg";



import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const initialState = { name: 'Name', description: 'Notes/Additional Data', counter: '' }

const App = () => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])
  const [counted, setCounted] = useState(0)

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function deleteTodo() {

     const todoDetails = {
      id: todos[0].id,
    };
    
    await API.graphql({ 
      query: mutations.deleteTodo, 
      variables: { input: todoDetails }
    });

  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items


      var count = 0
      todos.map(function(todo, i){
        count += todo.counter
      })
      setCounted(count)
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    try {
      var maniCount = isNaN(formState.counter) ? 0 : Number(formState.counter)
      if (!formState.name || !formState.description || maniCount < 1) return
      const todo = { ...formState }

      setTodos([...todos, { name: todo.name, description: todo.description, counter: todo.counter } ])
      setFormState(initialState)
      setCounted(0)
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
      fetchTodos()
    } catch (err) {
      console.log('error creating record:', err.errors[0])
    }
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
      <h2 style={styles.header2}>KDK STL MANI COUNTER</h2>
   
        <input
          onChange={event => setInput('counter', event.target.value)}
          style={styles.input}
          value={formState.counter}
          placeholder="Mani Count"
        /> 
        <button style={styles.button} onClick={addTodo}>Submit</button>
        <p style={styles.counted}>Current Total</p>
        { <p style={styles.count}>{counted}</p>  }
        <p style={styles.counted}>For the Benefit of All Beings!</p>
      </div>
    </div>
  )
}

const styles = {
  wrapper:{position: "fixed", width: "100vw", height: "100vh", backgroundSize: "100vh 100vh", backgroundPosition:'center', backgroundColor: 'black', backgroundRepeat:'no-repeat', backgroundImage: `url(${background})`, display: 'flex', flexDirection: 'column', justifyContent: 'center'},
  container: { padding: '0px', width: 373, height:'325px',border:'3px solid black',backgroundColor:"rgba(0,0,0,.7)", margin: '0 auto', display: 'flex', flexDirection: 'column', borderRadius: "10px" },
  todo: {  marginBottom: 15 },
  header2: {opacity:'1',color: 'white', backgroundColor:"black", borderRadius:'10px', textAlign: 'center', paddingBottom:'5px',paddingTop:'5px',margin:'0px', marginBottom:'5px'},
  input: { textAlign:'center', border: '1px solid white', color:"white", backgroundColor: "black",  borderRadius:'5px', marginBottom: 10, padding: 8, fontSize: 18, marginLeft:'45px', marginRight:'45px' },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  notes: { height: '400px' },
  counted: {fontWeight:'bold', color:'white', display: 'flex', justifyContent: 'center', fontSize: '24px', margin: '2px'},
  count: {color:'gold', fontWeight:'bold', fontStyle:'italic', display: 'flex', justifyContent: 'center', fontSize: '24px', margin: '2px'},
  button: { border: '1px solid white', borderRadius: '10px', marginLeft:'140px', marginRight:'140px', marginBottom:'55px', backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '10px 0px' }
}

export default App